const config = {
  siteTitle: 'Teguh Aprianto',
  siteTitleShort: 'Teguh Aprianto',
  siteTitleAlt: 'Teguh Aprianto',
  siteLogo: '/logos/new.png',
  siteUrl: 'https://teguh.co',
  repo: 'https://github.com/secgron/teguh',
  pathPrefix: '',
  dateFromFormat: 'YYYY-MM-DD',
  dateFormat: 'DD MMMM YYYY',
  siteDescription:
    'Situs ini berisi tulisan, opini dan juga merupakan dokumentasi untuk saya pribadi ketika sedang belajar atau membagikan sesuatu. Untuk mendapatkan informasi yang sifatnya cepat, silakan ikuti saya di Twitter @secgron. Jika ingin menghubungi saya, silakan menuju ke halaman Kontak.',
  siteRss: '/rss.xml',
  googleAnalyticsID: 'UA-101162957-1',
  postDefaultCategoryID: 'Tech',
  newsletter: 'https://teguh.substack.com',
  newsletterEmbed: 'https://teguh.substack.com/embed',
  userName: 'Teguh Aprianto',
  userEmail: 'security@teguh.co',
  userTwitter: 'secgron',
  SPOTIFY_CLIENT_ID: 'a2617a598ead4782a1ad01e28f8e6271',
  SPOTIFY_CLIENT_SECRET: '3b5c4282893a4b15a8efea8213b7f86e',
  SPOTIFY_REFRESH_TOKEN:'AQAx9rlU9TyPfO6TGSrDreWTm_-2Cn1Y8M6Qhsm-D-JvchVDNFNYuD0L8v2rz8rf5Vi3dx8rK1DnVfLwaYVe2WxmyYDC5ItKbxbusIoh0nY43Mg0_4vE1WPwtF63RbvfUWM',
  menuLinks: [
    {
      name: 'About',
      link: '/about/',
    },
    {
      name: 'Speaking',
      link: '/speaking/',
    },
    {
      name: 'Clients',
      link: '/portfolio/',
    },
    {
      name: 'Contact',
      link: '/contact/',
    },
  ],
  themeColor: '#3F80FF', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff',
}

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === '/') {
  config.pathPrefix = ''
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, '')}`
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === '/') config.siteUrl = config.siteUrl.slice(0, -1)

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== '/') config.siteRss = `/${config.siteRss}`

module.exports = config
