import React, { Component } from 'react'
import { Link } from 'gatsby'
import youtube from '../../content/thumbnails/utube.png'
import facebook from '../../content/thumbnails/fesbuk.png'
import linkedin from '../../content/thumbnails/linkedin.png'
import twitter from '../../content/thumbnails/twitter.png'
import github2 from '../../content/thumbnails/github.png'
import github from '../../content/thumbnails/gitblue.png'
import gatsby from '../../content/thumbnails/gatsby.png'
import amplify from '../../content/thumbnails/amplify.svg'
import instagram from '../../content/thumbnails/ig.png'
import telegram from '../../content/thumbnails/telegram.png'

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer container">
        <div>Powered by

          <a href="https://gatsbyjs.org" target="_blank" title="Gatsby">
            <img
              src={gatsby}
              target="_blank"
              rel="noopener noreferrer"
              className="footer-img"
              alt="Gatsby"
            />
          </a>

          <a href="https://www.github.com/" target="_blank" title="Github">
            <img
              src={github2}
              target="_blank"
              rel="noopener noreferrer"
              className="footer-img"
              alt="GitHub"
            />
          </a>

          <a href="https://aws.amazon.com/amplify/" target="_blank" title="AWS Amplify">
            <img
              src={amplify}
              target="_blank"
              rel="noopener noreferrer"
              className="footer-img"
              alt="AWS Amplify"
            />
          </a>

        </div>
        <div>
          <a href="https://facebook.com/secgrons" target="_blank" title="Facebook">
            <img
              src={facebook}
              target="_blank"
              rel="noopener noreferrer"
              className="footer-img"
              alt="Facebook"
            />
          </a>
           <a href="https://linkedin.com/in/secgron" target="_blank" title="Instagram">
            <img
              src={linkedin}
              target="_blank"
              rel="noopener noreferrer"
              className="footer-img"
              alt="Instagram"
            />
          </a>
          <a href="https://www.youtube.com/c/TeguhAprianto" target="_blank" title="YouTube">
            <img
              src={youtube}
              target="_blank"
              rel="noopener noreferrer"
              className="footer-img"
              alt="YouTube"
            />
          </a>
           <a href="https://twitter.com/secgron" target="_blank" title="Twitter">
            <img
              src={twitter}
              target="_blank"
              rel="noopener noreferrer"
              className="footer-img"
              alt="Twitter"
            />
          </a>
          <a href="https://instagram.com/secgron" target="_blank" title="Instagram">
            <img
              src={instagram}
              target="_blank"
              rel="noopener noreferrer"
              className="footer-img"
              alt="Instagram"
            />
          </a>
          <a href="https://www.github.com/secgron" target="_blank" title="Github">
            <img
              src={github}
              target="_blank"
              rel="noopener noreferrer"
              className="footer-img"
              alt="GitHub"
            />
          </a>
          <a href="https://t.me/secgron" target="_blank" title="Telegram">
            <img
              src={telegram}
              target="_blank"
              rel="noopener noreferrer"
              className="footer-img"
              alt="Telegram"
            />
          </a>
        </div>
      </footer>
    )
  }
}
